export const PDF_PATHS = {
  DOCUMENT_3_REASONS: {
    en: "/pdf/3_Reasons_Object_First_is_Best_for_Veeam_white_paper.pdf",
    de: "/pdf/3_Reasons_Object_First_is_Best_for_Veeam_white_paper_de.pdf",
    fr: "/pdf/3_Reasons_Object_First_is_Best_for_Veeam_white_paper_fr.pdf",
    es: "/pdf/3_Reasons_Object_First_is_Best_for_Veeam_white_paper_es.pdf",
    'es-419': "/pdf/3_Reasons_Object_First_is_Best_for_Veeam_white_paper_es_latam.pdf", // es-latam
    it: '/pdf/3_Reasons_Object_First_is_Best_for_Veeam_white_paper_IT.pdf',
    pt: "/pdf/3_Reasons_Object_First_is_Best_for_Veeam_white_paper_pt-br.pdf",
  },
  OF_DATA_SHEET: {
    en: "/pdf/EN_Datasheet_Ootbi_192TB.pdf",
    de: "/pdf/DE_Datasheet_Ootbi_192TB.pdf",
    fr: "/pdf/FR_Datasheet_Ootbi_192TB.pdf",
    es: "/pdf/Object-First-data-sheet_es.pdf",
    'es-419': "/pdf/ES_LATAM_Datasheet_Ootbi_192TB.pdf", // es-latam
    it: '/pdf/IT_Datasheet_Ootbi_192TB.pdf',
    pt: "/pdf/PT-BR_Datasheet_Ootbi_192TB.pdf",
  },
  ZERO_TRUST: {
    en: "/pdf/Zero_Trust_and_Enterprise_Data_Backup.pdf",
    de: "/pdf/Zero_Trust_and_Enterprise_Data_Backup_de.pdf",
    fr: "/pdf/Zero_Trust_and_Enterprise_Data_Backup_fr.pdf",
    es: "/pdf/Zero_Trust_and_Enterprise_Data_Backup_es.pdf",
    it: '/pdf/Zero_Trust_and_Enterprise_Data_Backup_it.pdf',
    pt: "/pdf/Zero_Trust_and_Enterprise_Data_Backup_pt-br.pdf",
  },
  NIS2_DIRECTIVE_WHITE_PAPER: {
    en: "/pdf/nis2_directive_whitepaper_en.pdf",
    de: "/pdf/nis2_directive_whitepaper_de.pdf",
    fr: "/pdf/nis2_directive_whitepaper_fr.pdf",
    es: "/pdf/nis2_directive_whitepaper_es.pdf",
    it: "/pdf/nis2_directive_whitepaper_it.pdf",
  },
} as const;
